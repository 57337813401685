import React from 'react';
import ProductItem from './ProductItem';


function ProductsMain() {
  return (
    <div className='products__main'>
      <p>
We are a B-BBEE level 2 and black-owned managed company. In that, we support our national developmental objectives and goals. As a level 2 B-BBEE company, our clients can claim 125% of their spending towards their preferential procurement. We also believe that the Broad-Based Black Economic Empowerment code (B-BBEE) seeks to accelerate and fast track the re-entry of previously marginalized communities into the mainstream of the economy.
        </p>
        <br/>
        <p>
        Furthermore, it endeavors to transform the South African economy to enable meaningful participation of black people, women, and rural communities in the mainstream of the economy in a manner that has a positive impact on training, employment, income redistribution, structure economy change, aviation skill transfer, readjustment, and economic growth.
        </p>
        <div className='products__container'>
                <div className='products__wrapper'>
                    <ul className='products__items'>
                        <ProductItem 
                          src='/images/about-us-02.png'
                          text='Our senior instructor is an ex South African Air Force Avionics Instructor with 16 years service in the Air Force
                          and 20 years commercial aviation experience, therefore we have both military and civilian facets to our training
                          curriculum.'
                          label=''
                          path='/'
                        />
                        <ProductItem 
                          src='/images/about-us-01.png'
                          text='The following SA Civil Aviation Authority approved courses are relevant to your specific trade which will be
                          provided free of charge to students who take the package deal of all 3 years that we offer. These certificates
                          are needed in order to get your Aircraft Maintenance Engineer license'
                          label=''
                          path='/'
                        />
                    </ul>
                  </div>
            </div>
            <p>
              Our courses are very "workbench" orientated and aim to produce a technician who can repair a component in the workshop, and further install it and test on the aircraft.
            </p>
    </div>
  );
}

export default ProductsMain;