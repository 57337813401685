import React from "react";
import './Maps.css';


function Maps() {
    return (

        <div className="maps-container">
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7192.833157009914!2d28.214756!3d-25.657482!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x40ac0c2dcf6bd0a7!2sWonderboom%20National%20Airport!5e0!3m2!1sen!2sus!4v1641799804072!5m2!1sen!2sus" width="100%" height="500" allowfullscreen="" title="Dentec Training Location" loading="lazy" />
        </div>
    )
  }


export default Maps;

