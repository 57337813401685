import React from 'react';
import './Products.css';
import { Link } from 'react-router-dom';

function ProductItem(props) {
  return (
    <>
      <li className='products__item'>
        <Link className='products__item__link' to={props.path}>
          <figure className='products__item__pic-wrap'>
            <img 
              className='products__item__img' 
              alt='About Us'
              src={props.src}
              />
          </figure>
          <div className='products_item_info'>
              <h3>{props.label}</h3>
              <p className='p_text'>{props.text}</p>
          </div>
        </Link>
      </li> 
    </>
  );
}

export default ProductItem;