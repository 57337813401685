import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar';
import Home from './components/pages/Home';
import AboutUs from './components/pages/AboutUs';
import SignUp from './components/pages/SignUp';
import Courses from './components/pages/Courses';
import Facilities from './components/pages/Facilities';
import Careers from './components/pages/Careers';
import Railway from './components/pages/Railway';
import Mining from './components/pages/Mining';
import Petrochemical from './components/pages/Petrochemical';
import Automotive from './components/pages/Automotive';
import Telecommunication from './components/pages/Telecommunication';
import ContactUs from './components/pages/ContactUs';
import ScrollToTop from './components/ScrollToTop';


function App() {
    return (
        <>
        <Router>
        <ScrollToTop />
            <Navbar />
                <Switch>
                    <Route path='/' exact component={Home} />
                    <Route path='/about-us'  component={AboutUs} />
                    <Route path='/courses'  component={Courses} />
                    <Route path='/facilities'  component={Facilities} />
                    <Route path='/careers'  component={Careers} />
                    <Route path='/railway'  component={Railway} />
                    <Route path='/petrochemical'  component={Petrochemical} />
                    <Route path='/mining'  component={Mining} />
                    <Route path='/telecommunication'  component={Telecommunication} />
                    <Route path='/contact-us'  component={ContactUs} />
                    <Route path='/automotive'  component={Automotive} />
                    <Route path='/sign-up'  component={SignUp} />
                </Switch>
        </Router>

        </>

    );

}

export default App;