import React from 'react';
import './aboutheadline.css';
import Logo01 from '../assets/teta-logo.png';
import Logo02 from '../assets/sacaa-logo.png';
import Logo03 from '../assets/qcto-logo.png';

const ColoredLine = ({ color }) => (
  <hr
      style={{
          color: color,
          backgroundColor: color,
          height: 2,
          width: 350,
          margin: 10
      }}
  />
);

function AboutHeadline() {
  return (
    <div className='abouthome-container'>
      <section className='abouthome-subscription'>
        
        
        <div className='input-areas'>
          <h2 className='abouthome-quick-links'>WHY WE ARE DIFFERENT</h2>
          <ColoredLine />
        </div>
        <p className='abouthome-subscription-heading'>
        The courses are all Transport Education Training Authority (TETA) and South African Civil Aviation Authority (SACAA) approved and are in alignment with their standards. 
        </p>
        <p className='abouthome-subscription-heading'>
        The majority of course content is based on training manuals used by the Federal Aviation Administration (FAA) in the USA and the European Aviation Safety Association (EASA) in Europe. 
        </p>
      </section>
      <div>
      <img src={Logo02} />
      <img src={Logo03} />
      <img src={Logo01} />
      </div>
    </div>
  );
}

export default AboutHeadline;