import React from 'react';
import './aboutDentec.css';
import { Link } from  'react-router-dom';

const AboutDentec = () => {
    return (
        <div className="gpt3__aboutDentec section__padding">
            <div className='gpt3__aboutDentec-content'>
                <h1>We offer a three-year theoretical and practical syllabus for the Aircraft Maintenance Mechanic Trade</h1>
                <p>
                    We would like to welcome all prospective students locally and international who are interested in the trade of Aircraft Maintenance Mechanic.   
                </p>
                <p>The courses are all Transport Education Training Authority (TETA) and South African Civil Aviation Authority (SACAA) approved and are in alignment with their standards.</p>
                <p>We are situated at Wonderboom Airport in Tshwane, South Africa. We offer a three-year theoretical and practical syllabus for the Aircraft Maintenance Mechanic trade. 
                    Demonstrating our dedication to the aviation industry and the development of essential skills in South
                    Africa and Africa as a whole
                </p>
                <p>
                    The majority of course content is based on training manuals used by Federal Aviation Administration (FAA) in the USA and the European Aviation Safety Association (EASA) in Europe.
                </p>
                <div className="gpt3__header-content__input">
                    <Link to='/courses'>
                        <button type="button">EXPLORE MORE</button>
                    </Link>
                </div>
                
            <p><br/></p>
            </div>
            
        </div>
    )
}

export default AboutDentec;
