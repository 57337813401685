import React from 'react';
import Article from '../../components/article/Article';
import { blog01, blog02, blog03, blog04, blog05 } from './imports';
import './blog.css';
import { motion } from 'framer-motion';


const Blog = () => (
  <div className="gpt3__blog section__padding" id="blog">
    {/**
     * 
     * 
     * <div className="gpt3__blog-heading">
            <h1 className="gradient__text"> REQUIREMENTS FOR THIS COURSE: <br /></h1>
            <p>CANDIDATES MUST BE OVER 16 YEARS OF AGE AND HAVE PASSED GRADE 12 WITH : ENGLISH, SCIENCE, PURE MATHS OR MATHS LITERATURE</p>
        </div>
     * 
     * 
     */}
    
    <div className="gpt3__blog-container">
      <motion.div
        whileInView={{ opacity: 1 }}
        whileHover={{ scale: 1.1 }}
        transition={{ duration: 0.5, type: 'tween' }}  
        className="gpt3__blog-container_groupA"
      >
        <Article imgUrl={blog01} 
                date="FIRST YEAR" 
                text="The FIRST YEAR takes place in a classroom with the usual multimedia training presentation approach,
                providing theoretical knowledge of the course content, as well as the students having to build practical projects
                in a controlled and helpful environment and learning hand tool skills. After successful completion of all the modules in this year, the student receives a certificate stating that they have completed the First Year Basic course in that trade.
                " 
                duration="Duration: 10 months" 
        />
      </motion.div>
    <div className="gpt3__blog-container_groupA">
      <motion.div
        whileInView={{ opacity: 1 }}
        whileHover={{ scale: 1.1 }}
        transition={{ duration: 0.5, type: 'tween' }}  
        className="gpt3__blog-container_groupA"
      >
        <Article imgUrl={blog01} 
                date="SECOND YEAR" 
                text="The SECOND YEAR involves the students working on actual components in the workshop under the tutoring of a technician or an engineer qualified in that field of aviation, and faultfinding and repairing snags on operational aircraft with relevantly qualified engineers on those aircraft systems. During this year, the student's Logbook is filled in for each practical task that they perform." 
                duration="Duration: 12 months"
        />
        </motion.div>
    </div>
    <div className="gpt3__blog-container_groupA">
    <motion.div
        whileInView={{ opacity: 1 }}
        whileHover={{ scale: 1.1 }}
        transition={{ duration: 0.5, type: 'tween' }}  
        className="gpt3__blog-container_groupA"
      >
        <Article imgUrl={blog01} 
                date="THIRD YEAR" 
                text="The THIRD YEAR is a continuation of the SECOND YEAR and culminates in the student going for a TRADE TEST PREPARATION course and writing the final qualification exam. During this year the student will also do the Human Factors Course, Compass Swing Course, the relevant trade related CAT X course and Civil Aviation Regulations courses for which they will receive official certificates recognized by the South African Civil Aviation Authority and this will be the first step towards getting an Aircraft Maintenance Engineer’s license." 
                duration="Duration: 12 months"
        />
      </motion.div>
    </div>
     {/* 
        <div className="gpt3__blog-container_groupB">
            <Article imgUrl={blog02} date="Sep 26, 2021" text="GPT-3 and Open  AI is the future. Let us exlore how it is?" />
            <Article imgUrl={blog03} date="Sep 26, 2021" text="GPT-3 and Open  AI is the future. Let us exlore how it is?" />
            <Article imgUrl={blog04} date="Sep 26, 2021" text="GPT-3 and Open  AI is the future. Let us exlore how it is?" />
            <Article imgUrl={blog05} date="Sep 26, 2021" text="GPT-3 and Open  AI is the future. Let us exlore how it is?" />
        </div>
     */} 
     
    </div>
  </div>
);

export default Blog;