import React from 'react';
import './trade.css';

const Trade = ({ imgUrl, date, text, code }) => (
  <div className="gpt3__trade-container_article">
    <div className="gpt3__trade-container_article-image">
      <img src={imgUrl} alt="trade_image" />
    </div>
    <div className="gpt3__trade-container_article-content">
      <div>
        <h1>{date}</h1>
        <h3>{text}</h3>
      </div>
      <h1>{code}</h1>
    </div>
  </div>
);

export default Trade;