import React from 'react';
import { motion } from 'framer-motion';
import '../App.css';
import './HeroSection.css';
import './header.css';
import { Link } from 'react-router-dom';
//import { Link } from 'react-router-dom';

const animations = {
    initial: { opacity: 0, x: 100 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: -100 },
  };


const Header = () => {
    return (
        <div className="gpt3__header section_padding" id='home'>
                
                    <motion.div
                        variants={animations}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        transition={{ duration: 1 }}
                        className="gpt3__header-content"
                    >
                    <h1>DENTEC AVIATION TECHNICAL TRAINING SCHOOL</h1>
                    <p>Join one of SA's leading Aviation Technical Training Institution today. </p>
                    <div className="gpt3__header-content__input">
                        <Link to="/about-us">
                            <button type="button">Get Started</button>
                        </Link>
                    </div>
                    </motion.div>
            <div className="gpt3__header-image">
               {/**<img src={ai} alt="ai" /> */}
            </div>
        </div>
    )
}

export default Header;
