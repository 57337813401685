import React, { useState }  from 'react';
import { Link } from  'react-router-dom';

import { RiMenu3Line, RiCloseLine, RiMailAddLine, RiEditBoxLine } from 'react-icons/ri';
//import { TiEdit } from 'react-icons/ti';
import logo from '../assets/Dentec-Training-Logo.png';
import './navbar_1.css';

const Menu = () => (
    <>
        <Link to='/' className='navbar-link'>
            <p>Home</p>
        </Link>
        <div className='navbar-link'>
          <Link to='/about-us'>
              <p>About Us</p>
          </Link>
        </div>
        
        <Link to='/courses' className='navbar-link'>
            <p>Courses</p> 
        </Link>
        <Link to='/facilities' className='navbar-link'>
            <p>Facilities</p> 
        </Link>
        <Link to='/careers' className='navbar-link'>
        <p>Careers</p>
        </Link>
      
    </>
)

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <div className="gpt3__navbar">
      <div className="gpt3__navbar-links">
        
        <div className="gpt3__navbar-links_logo">
          <img src={logo} alt='Dentec Logo Aviation Technical School'/>
        </div>
        
        <div className="gpt3__navbar-links_container">
          <Menu />
        </div>
      
      </div>
      <div className="gpt3__navbar-sign">
        <Link to='/contact-us'>
            <button type="button"><div className='iconStatus'><RiEditBoxLine size={20} />Enrolment</div></button>
        </Link>
      
        <Link to='/contact-us'>
          <button type="button"><div className='iconStatus'><RiMailAddLine size={20} />Contact Us</div></button>
        </Link>
      </div>
      <div className="gpt3__navbar-menu">
        {toggleMenu
          ? <RiCloseLine color="#283c8d" size={27} onClick={() => setToggleMenu(false)} />
          : <RiMenu3Line color="#283c8d" size={27} onClick={() => setToggleMenu(true)} />}
        {toggleMenu && (
        <div className="gpt3__navbar-menu_container scale-up-center">
          <div className="gpt3__navbar-menu_container-links">
            <Menu />
          </div>
          <div className="gpt3__navbar-menu_container-links-sign">
            <Link to='/contact-us'>
              <button type="button">Enrolment</button>
            </Link>
            <Link to='/contact-us'>
              <button type="button">Contact Us</button>
            </Link>
          </div>
        </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
