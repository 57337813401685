import React from 'react';
import './facilities.css';
import palogos from '../../assets/partner-logos.png';

const FacilitiesMain = () => {
    return (
        <div className="gpt3__aboutDentec section__padding">
            <div className='gpt3__aboutDentec-content'>
                <h2 className='abouthome-quick-links'>Every student can choose to do this phase of the apprenticeship at any other company of their
choice if they do not wish to take advantage of our package deal Through our Sister company Dentec MRO who has USA FAA-Approved 14 CFR Part 145 Repair Station Certificate Number: CAPY983D</h2>
                
                <div>
                    <img src={palogos} alt='Dentec Logo Aviation Technical School'/>
                </div>
                <p className='p_text'> 
                The courses are all Transport Education Training Authority (TETA) and South African Civil Aviation Authority (SACAA) approved and are in alignment with their standards.
                </p>
                <p>
                The majority of course content is based on training manuals used by Federal Aviation Administration (FAA) in the USA and the European Aviation Safety Association (EASA) in Europe.    
                </p>
            </div>
            
        </div>
    )
}

export default FacilitiesMain;
