import React from 'react';
import './careers.css';

const Careers = () => {
    return (
        <div className="careers section__padding">
            <div className='careers-content'>
                <h1 className='abouthome-quick-links'>TRAINING PROSPECTUS</h1>
                <p>
                    Dentec Aviation Technical Training School is situated at Wonderboom Airport in Tshwane, South Africa. It 
offers a three-year theoretical and practical syllabus for the Aircraft Maintenance Mechanic trade. It 
demonstrates the group dedication to the aviation industry and to the development of essential skills in South 
Africa and Africa as a whole.
                </p>
                <br/>
                <p>
                Dentec Aviation Technical Training school would like to welcome all prospective students local and 
international who are interested in the trade of Aircraft Maintenance Mechanic.
Dentec Aviation Technical Training school is hereby inviting applications from suitably qualified students 
interested in a career in Aviation Maintenance to specialise in the trade of "Aircraft Maintenance Mechanic".
                </p>
                <br/>
                <p>
                <strong>
                Once a short-list has been identified, the selection of students for this technical training will be based on 
the results of their background screening check results, aptitude tests, personal interviews and an 
additional final interview with management.
                </strong>
                </p>
                <br/>
                <p>
                On completion of the 1st year basic theory training, successful students will have to complete another 18 
months to 2 years of On-The-Job practical training which is also a requirement from TETA/NAMB/QCTO, 
SACAA, FAA, and EASA.
                </p>
                <br/>
                <p>
                As a student on training, TETA experience logbook will have to be completed during this time to provide proof 
of experience gained. This period adds up to a minimum time of +72 weeks of experience.
After the three-year theory and practical training is completed, the student will be required to apply for a trade 
test with TETA (Transport Education Training Authority) in order to qualify as an Aircraft Maintenance 
Mechanic
                </p>
                <h2 className='abouthome-quick-links'>PROGRAM CONTENT:</h2>
                <h3 className='abouthome-quick-links'>REQUIREMENTS FOR THIS COURSE:</h3>
                <br/>
                <p>
                If you are a South African Citizen and in Grade 12 (N3) and preferably younger than 25 years of age and/or 
an international student equivalent to O- Level (+N4) Mathematics (+50 %), Physical science (+50%) English 
(+50%) and have a passion to work on an aircraft, now is the time to contact us!
Students with N3, N4 Aircraft Technology and O - Level could have an added advantage. We train both Fixedwing and Rotary wing component technical know-how.
                </p>
                <h3 className='abouthome-quick-links'>DURATION</h3>
                <br/>
                <p>
                    <strong>FIRST YEAR</strong> - theory training and practical classroom projects (10 months) Certificate issued on completion. </p>
                <p>
                    <strong>SECOND YEAR</strong> - practical faultfinding on components on the workbench and on aircraft (12 months) </p>
                <p>
                    <strong>THIRD YEAR</strong> - practical faultfinding on components on the workbench and on aircraft and trade test prep (12months)
                </p>
                <h3 className='abouthome-quick-links'>TRADES OFFERRED:</h3>
                <br/>
                <p><strong>AIRCRAFT AVIONICIAN </strong></p>
                <p><strong>AIRCRAFT INSTRUMENT TECHNICIAN </strong></p>
                <p><strong> AIRCRAFT ELECTRICAL TECHNICIAN</strong></p>
                <p><strong>AIRCRAFT RADIOTRICIAN</strong></p>
                <h3 className='abouthome-quick-links'>COST:</h3>
                <br/>
                <p><strong>Please send us an email to enquire about the course prices and the various other packages we offer. Cost 
includes all text books, training material and electronic projects.</strong></p>
                <h3 className='abouthome-quick-links'>BRIEF DESCRIPTION:</h3>
                <br/>
                <p>
                These courses are very "workbench" orientated and aim to produce a technician that can repair a component 
in the workshop, and then take it to the aircraft and fit and test that component on the aircraft.<br/>
The majority of course content is based on training manuals used by the Federal Aviation Administration (FAA) 
in the USA and the European Aviation Safety Association (EASA) in Europe. <br/>
The courses are all Transport Education Training Authority (TETA) and South African Civil Aviation Authority 
(SACAA) certified, approved and are in alignment with their standards.<br/>
Our senior instructor is an ex South African Air Force Avionics Instructor with 16 years' service in the Air Force 
and 20 years commercial aviation experience, therefore we have both military and civilian facets to our training 
curriculum
                </p>
                <br/>
                <p>
                The <strong>FIRST YEAR</strong> takes place in a classroom with the usual multimedia training presentation approach, 
providing theoretical knowledge of the course content, as well as the students having to build practical projects 
in a controlled and helpful environment and learning hand tool skills. After successful completion of all the 
modules in this year, the student receives a certificate stating that they have completed the First Year Basic 
course in that trade.<br/> 
Duration: 10 months
                </p>
                <br/>
                <p>
                The <strong>SECOND YEAR</strong> involves the students working on actual components in the workshop under the tutoring of a technician or an engineer qualified in that field of aviation, and faultfinding and repairing snags on operational aircraft with relevantly qualified engineers on those aircraft systems. During this year, the student's Logbook is filled in for each practical task that they perform.
Duration: 12 months
                </p>
                <p><strong> NOTE: The student can choose to do this phase of the apprenticeship at any other company of their 
choice if they do not wish to take advantage of our package deal.</strong></p>
<br/>
                <p>
                The <strong>THIRD YEAR</strong> is a continuation of the SECOND YEAR and culminates in the student going for a TRADE 
TEST PREPARATION course and writing the final qualification exam. During this year the student will also do the Human Factors Course, Compass Swing Course, the relevant trade-related CAT X course and Civil 
Aviation Regulations courses for which they will receive official certificates recognized by the South African Civil Aviation Authority and this will be the first step towards getting an Aircraft Maintenance Engineer's license.<br/> 
Duration: 12 months
                </p>
                <p><strong>NOTE: The student can choose to do this phase of the apprenticeship at any other company of their 
choice if they do not wish to take advantage of our package deal.
</strong></p>
            </div>
            
        </div>
    )
}

export default Careers;
