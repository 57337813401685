import React from 'react';
import './Cards.css';
import ModulesMain from './ModulesMain';

function Cards() {
  return (
    <div className='cards'>
      
      <div id="core-modules" className='cards__container'>
        <ModulesMain />
      </div>
    </div>
  );
}

export default Cards;