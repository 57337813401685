import React from 'react'
import Cards from '../Cards';
//import RequestForm from '../RequestForm';
//import ContactTop from '../ContactTop';
//import ContactList from '../ContactList';
//import VisionValues from '../VisionValues';
import ContactDetails from '../ContactDetails';
import Maps from './Maps';
import Footer from '../footer/Footer';
import HeroSectionContactUs from '../HeroSectionContact';

function ContactUs() {
    return (
        <div>
            <HeroSectionContactUs />
            <Cards />
            {/*<ContactTop />*/}
            <ContactDetails />
            {/*<ContactList />*/}
            <Maps />
            <Footer />
        </div>
    )    
}
export default ContactUs;