import React from 'react';
import './courses.css';
import { Link } from 'react-router-dom';
import prospectus from '../../assets/PROSPECTUS-INFORMATION.pdf';

const Courses = () => {
    return (
        <div className="gpt3__aboutDentec section__padding">
            <div className='gpt3__aboutDentec-content'>
                <h2 className='abouthome-quick-links'>THESE COURSES ARE VERY "WORKBENCH" ORIENTATED AND AIM TO PRODUCE A TECHNICIAN THAT CAN REPAIR A COMPONENT IN THE WORKSHOP.</h2>
                
                {/**<Link to={prospectus} target="_blank" download> */}
                <Link to={prospectus} target="_blank">
                    <button type="button">DOWNLOAD PROSPECTUS</button>
                </Link>
            <p><br/></p>
            </div>
            
        </div>
    )
}

export default Courses;
