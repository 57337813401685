import React from 'react';
import './whatDentecT2.css';
import { motion } from 'framer-motion';

const animations = {
    initial: { opacity: 0, x: 100 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: -100 },
  };

const WhatDentecT2 = () => {
    return (
        <div className="gpt3__whatDentecT2 section__padding">
            
            <motion.div
                whileInView={{ opacity: 1 }}
                whileHover={{ scale: 1.1 }}
                transition={{ duration: 0.5, type: 'tween' }}
                className="gpt3__whatDentec-content"
            >
                 <h1>Dentec Aviation Technical Training School Private Limited</h1>
                 <p>
                 We are a B-BBEE level 2 and black-owned managed company. In that, we support our national developmental objectives and goals. As a level 2 B-BBEE company, our clients can claim 125% of their spending towards their preferential procurement. We also believe that the Broad-Based Black Economic Empowerment code (B-BBEE) seeks to accelerate and fast track the re-entry of previously marginalized communities into the mainstream of the economy.   
                 </p>
                 <p>
                 Furthermore, it endeavors to transform the South African economy to enable meaningful participation of black people, women, and rural communities in the mainstream of the economy in a manner that has a positive impact on training, employment, income redistribution, structure economy change, aviation skill transfer, readjustment and economy growth.
                 </p>
            </motion.div>
                    
            
        </div>
    )
}

export default WhatDentecT2;
