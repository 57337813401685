import React from 'react'
import '../../App.css';
import Cards from '../Cards';
//import News from '../News';
import Footer from '../footer/Footer';
import WhatDentecT2 from '../whatDentecT2/WhatDentecT2';
import AboutHome from '../abouthome/AboutHome';
import AboutDentec from '../about/AboutDentec';
import Requirements from '../requirements/Requirements';
import HeroSection from '../HeroSection';
import Blog from '../blog/Blog';
import Trades from '../trades/Trades';
//import { newslist } from '../Newslist';


function Home() {
    return (
        <>
            <HeroSection />
                <Cards />
                <WhatDentecT2 />
                <Trades />
                <AboutHome />
                <AboutDentec />
                <Requirements />
                <Blog />
            <Footer />
        </>
    )    
}
export default Home;