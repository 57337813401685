import React from 'react'
import CardItem from './ContactDetailsItem';
import './VisionValues.css';

function ContactDetails({ newslist }) {
    return (
        <div className='vision__values'>
            <div className='vision_values_container'>
            <h2>You are invited to contact Dentec Aviation Technical Training School either by phone, email or fax</h2>
            <br/> 
            <p>Our normal business hours are from 7:00 am to 5:00 pm, Monday to Friday</p>
            <br/>
            <h2>Send Us a Message!</h2>
            <p>One of our sales specialists will get back to you as soon as possible.</p>
                <div className='vision_values_wrapper'>
                  <ul className='vision_values_items'>
                    <CardItem
                      src=''
                      text=''
                      fax='Building T213
                      Wonderboom Airport
                      South Africa
                      0017'
                      label='Head Office'
                      path='/'
                    />
                    <CardItem
                      src=''
                      text='Tel :	+27 12 567 7312/3'
                      fax='Fax :	+27 12 567 7320'
                      label='Telephone'
                      path='/'
                    />
                    <CardItem
                      src=''
                      text=''
                      fax='info@dentecaviationtraining.com'
                      label='Email'
                      path='/'
                    />
                    
                  </ul>
                </div>
              </div>
        </div>
    )
};

export default ContactDetails;