import React from 'react';
import { Button } from './Button';
import '../App.css';
import './HeroSection.css';

function HeroSectionFacilities() {
    return (
        <div className='hero-container-facilities'>
            <div className='hero-container-inner'>
            {/*
            
            <h1>Facilities</h1>
            
            <div className='hero-btns'>
                <Button 
                    className='btns'
                    buttonStyle='btn--primary'
                    buttonSize='btn--large'
                >
                ENQUIRE
                </Button>
            </div>

            */}
            
            
            </div>
            
        </div>
    )
}

export default HeroSectionFacilities;
