import React from 'react';
import './modules.css';


function ModulesMain() {
  return (
    <div className='products__main_'>
      <div className='gpt3__modules'>
        <div className='gpt3__modules-content'><h1>OUR CORE MODULES</h1></div>     
      </div>
      
                    <ul className='products__items'>
                        <div className='gpt3__modules_01'>
                          <ul>
                            <li>Introduction to Aviation</li>
                            <li>Gyroscopic Compass Systems</li>
                            <li>Engine Performance Instruments</li>
                            <li>Introduction to Aviation</li>
                            <li>Aviation Safety </li>
                            <li>Human Factors </li>
                            <li>Hand Tools </li>
                            <li>Power Tools </li>
                            <li>Aircraft Hardware</li>
                            <li>Aircraft Materials</li>
                            <li>Measuring Instruments</li>
                            <li>Soldering Techniques</li>
                          </ul>
                        </div>
                        <div className='gpt3__modules_01'>
                        <ul>
                            <li>Pilot Static</li>
                            <li>Basic Electricity</li>
                            <li>Basic Electronics</li>
                            <li>Basic Digitals</li>
                            <li>Theory of flight</li>
                            <li>Aircraft Wiring </li>
                            <li>Synchro's and Servo's</li>
                            <li>Aviation Regulations</li>
                            <li>Fault Finding Procedures</li>
                            <li>Electrostatic Sensitive Devices</li>
                            <li>ARINC 429</li>
                            <li>Aircraft Antenna Testing</li>
                            <li>ELT testing</li>
                          </ul>
                        </div>
                        <div className='gpt3__modules_01'>
                          <ul>
                            <li>Pilot Static</li>
                            <li>Gyroscopic Compass Systems</li>
                            <li>Engine Performance Instruments</li>
                            <li>Aircraft Radio Communication</li>
                            <li>Instrument Landing Systems</li>
                            <li>Life Support Systems</li>
                            <li>Central Air Data Computer</li>
                            <li>Flight Recorder System (the Black Box)</li>
                            <li>Auto-pilot</li>
                            <li>AC Machines</li>
                            <li>Transformers</li>
                            <li>AC Control Equipment</li>
                            <li>Inverter and Transformer Rectifier Units</li>
                            <li>Ignition Systems</li>
                            <li>Aircraft Lighting</li>
                            <li>Electrical Units</li>
                            <li>Compass Swing Calibration</li>
                          </ul>
                        </div>
                        
                        
                    </ul>
    </div>
  );
}

export default ModulesMain;