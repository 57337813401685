import React from 'react';
import ProductItem from './ProductItem';


function ProductsMain() {
  return (
    <div className='products__main'>
        <div className='products__container'>
                <div className='products__wrapper'>
                    <ul className='products__items'>
                        <ProductItem 
                          src='images/about-us-02.png'
                          text='Avionics are the electronic systems used on aircraft, artificial satellites, and spacecraft. Avionic systems include communications, navigation, the display and management of multiple systems, and the hundreds of systems that are fitted to aircraft to perform individual functions.'
                          label='AIRCRAFT AVIONICIAN'
                          path='/products'
                        />
                        <ProductItem 
                          src='images/f-instruments.jpg'
                          text='Flight instruments are the instruments in the cockpit of an aircraft that provide the pilot with information about the flight situation of that aircraft, such as altitude, airspeed and direction.'
                          label='AIRCRAFT INSTRUMENT TECHNICIAN'
                          path='/products'
                        />
                    </ul>
                    <ul className='products__items'>
                        <ProductItem 
                          src='images/courses-01.png'
                          text='Aircraft are operated by complex electrical systems. Individual components such as lighting, landing gear, pilot instrumentation, and ignition systems are each run by an electrical system. Aircraft electricians are specially trained to install and maintain these systems.'
                          label='AIRCRAFT ELECTRICAL TECHNICIAN'
                          path='/products'
                        />
                        <ProductItem 
                          src='images/courses-04.png'
                          text='The job of an aircraft radiotrician is to carry out the maintenance of all communication systems on the aircraft, including radar and related systems...'
                          label='AIRCRAFT RADIOTRICIAN'
                          path='/products'
                        />
                    </ul>
                  </div>
            </div>
            <div class="gpt3__footer-heading">
              <h2>The courses are all Transport Education Training Authority (TETA) and South African Civil Aviation Authority (SACAA) approved and are in alignment with their standards.
The majority of course content is based on training manuals used by Federal Aviation Administration (FAA) in the USA and the European Aviation Safety Association (EASA) in Europe.</h2>
            </div>
    </div>
  );
}

export default ProductsMain;