import React from 'react'
import '../../App.css';
import Footer from '../footer/Footer';
//import HeroSectionAbout from '../HeroSectionAbout';
import AboutMain from '../AboutMain-2';
import AboutHeadline from '../AboutHeadline';
//import VisionValues from '../VisionValues';
import Cards from '../Cards';
import Slider from './Slider';

function AboutUs() {
    return (
        <div>
           {/*<HeroSectionAbout />*/}
            <Slider />
            <Cards />
            <AboutHeadline />
            <AboutMain />
                <Cards />
            <Footer />
        </div>
    )    
}
export default AboutUs;