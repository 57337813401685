import React from 'react'
import '../../App.css';
import Cards from '../Cards';
import Footer from '../footer/Footer';
import ProductsMain from '../facilities/ProductsMain';
import HeroSectionFacilities from '../HeroSectionFacilities';
import FacilitiesMain from '../facilities/Facilities';
import Blog from '../blog/Blog';
import Requirements from '../requirements/Requirements';
import FacilitySlider from './facilitySlider';

function Facilities() {
    return (
        <div>
            {/* <HeroSectionFacilities />*/}
             
            <FacilitySlider />
            <Cards />
            <FacilitiesMain />
            <ProductsMain />
            
            <Footer />
        </div>
    )    
}
export default Facilities;