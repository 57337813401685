import React from 'react';
//import gpt3Logo from '../../logo.svg';
import gpt3Logo from '../../assets/Dentec-Training-Logo-White-01.png';
import sacaaLogo from '../../assets/sacaa_logo.jpg';
import TETALogo from '../../assets/TETA_Logo.png';
import './footer.css';
import { Link } from 'react-router-dom';

const Footer = () => (
    <div>
        <div className="gpt3__footer_top section__padding">
            <div className="gpt3__footer-heading">
            <h1>Dentec Aviation Technical Training school
        is hereby inviting applications from suitably qualified students
        interested in a career in the trade of Aircraft Maintenance</h1>
            </div>
            <div className="gpt3__header-content__input">
                <Link to="/contact-us">
                    <button type="button">Appy Now</button>
                </Link>
            </div>
            <p><br/></p>
            
            
        </div>
        <div className="gpt3__footer section__padding">
              <div className="gpt3__footer-links">
                    <img src={gpt3Logo} alt="gpt3_logo" />
                <div className="gpt3__footer-links_logo">
                    
                </div>
                <div className="gpt3__footer-links_div">
                    <h4>Our Modules</h4>
                    <p><a href="/courses">• Introduction to Aviation </a></p>
                    <p><a href="/courses">• Aviation Safety</a></p>
                    <p><a href="/courses">• Human Factors</a> </p>
                    <p><a href="/courses">• Aircraft Hardware</a></p>
                    <p><a href="/courses">• Aircraft Materials</a></p>
                    <p><a href="/courses">• Basic Electronics</a></p>
                </div>
                
                <div className="gpt3__footer-links_div">
                    <h4>Get in touch</h4>
                    <p>info@dentecaviationtraining.com</p>
                    <p>Office: +27 12 567 7312/3</p>
                    <p>Fax:  +27 12 567 7320</p>

                    <p></p>
                    <p>Building T213</p>
                    <p>Wonderboom Airport</p>
                    <p>South Africa</p>
                    <p>0017</p>
                </div>
                </div>

                <div className="gpt3__footer-copyright">
                <p>dentecaviationtraining.com © 2022 - All rights reserved.</p>
                </div>
        </div>
  </div>
);

export default Footer;