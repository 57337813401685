import React from 'react';
import './requirements.css';
//import { google, dropbox, slack, shopify } from './imports';

const Requirements = () => {
    return (
        <div className="gpt3__requirements section__padding">
            <div className='gpt3__requirements-content'>
                <h1>REQUIREMENTS FOR THIS COURSE:</h1>
                <p>CANDIDATES MUST BE OVER 16 YEARS OF AGE AND HAVE PASSED GRADE 12 WITH : ENGLISH, SCIENCE, PURE MATHS OR MATHS LITERACY</p>
            </div>
            
        </div>
    )
}

export default Requirements;
