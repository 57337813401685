import { motion } from 'framer-motion';
import React from 'react';
import Article from '../../components/article/Trade';
import { trade02, trade03, trade04, trade05 } from './imports';
import './trades.css';

const animations = {
  initial: { opacity: 0, y: 100 },
  animate: { opacity: 1, y: 0 },
  whileInView: { opacity: 1 },
  exit: { opacity: 0, y: -100 },
};

const Trades = () => (
  <motion.div className="gpt3__trade section__padding" id="trade">
    <motion.div
        whileInView={{ opacity: 1 }}
        whileHover={{ scale: 1.1 }}
        transition={{ duration: 0.5, type: 'tween' }}  
        className="gpt3__trade-heading"
    >
      <h1>TRADES OFFERED</h1>
    </motion.div>
    <div className="gpt3__trade-container">
      <motion.div 
        variants={animations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 1 }}
        className="gpt3__trade-container_groupB"
      >
        <motion.div
          whileInView={{ opacity: 1 }}
          whileHover={{ scale: 1.1 }}
          transition={{ duration: 0.5, type: 'tween' }}  
          className="gpt3__blog-container_groupA"
        >
          <Article  imgUrl={trade02} 
                  date="AIRCRAFT AVIONICIAN" 
                  text="Avionics are the electronic systems used on aircraft, artificial satellites, and spacecraft. Avionic systems include communications, navigation, the display and management of multiple systems, and the hundreds of systems that are fitted to aircraft to perform individual functions."
                  code="OFO Code: 672101"
        />
        </motion.div>
        
        <motion.div
          whileInView={{ opacity: 1 }}
          whileHover={{ scale: 1.1 }}
          transition={{ duration: 0.5, type: 'tween' }}  
          className="gpt3__blog-container_groupA"
        >
          <Article  imgUrl={trade03} 
              date="AIRCRAFT INSTRUMENT TECHNICIAN" 
              text="Flight instruments are the instruments in the cockpit of an aircraft that provide the pilot with information about the flight situation of that aircraft, such as altitude, airspeed and direction."
              code="OFO Code: 672108"
          />
        </motion.div>
        
        <motion.div
          whileInView={{ opacity: 1 }}
          whileHover={{ scale: 1.1 }}
          transition={{ duration: 0.5, type: 'tween' }}  
          className="gpt3__blog-container_groupA"
        >
          <Article  imgUrl={trade04} 
              date="AIRCRAFT ELECTRICAL TECHNICIAN" 
              text="Aircraft are operated by complex electrical systems. Individual components such as lighting, landing gear, pilot instrumentation, and ignition systems are each run by an electrical system. Aircraft electricians are specially trained to install and maintain these systems." 
              code="OFO Code: 672105"
          />
        </motion.div>
        
        <motion.div
          whileInView={{ opacity: 1 }}
          whileHover={{ scale: 1.1 }}
          transition={{ duration: 0.5, type: 'tween' }}  
          className="gpt3__blog-container_groupA"
        >
          <Article  imgUrl={trade05} 
                date="AIRCRAFT RADIOTRICIAN" 
                text="The job of an aircraft radiotrician is to carry out the maintenance of all communication systems on the aircraft, including radar and related systems..."
                code="OFO Code: 672102" 
          />
        </motion.div>
      </motion.div>
    </div>
  </motion.div>
);

export default Trades;