import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css'
import './slider-animations.css';
import './style.css';


const slides = [
    { title: 'Courses', description: 'The courses are all Transport Education Training Authority (TETA) and South African Civil Aviation Authority (SACAA) approved and are in alignment with their standards', button: 'Discover', image: '/static/media/careers-dentec.ead9acaf.png' },
    { title: 'Facilities', description: 'Dentec Aviation Technical Training school would like to welcome all prospective students local and international who are interested in the trade of Aircraft Maintenance Mechanic.', button: 'Discover', image: '/static/media/careers-dentec.ead9acaf.png'}
  ];

const careerSlider = () => {
  return (
    <div>
        <Slider autoplay={3000} className="slider-wrapper">
            {slides.map((slide, index) => <div key={index} style={{ background: `url('${slide.image}') no-repeat center center`}} className="slider-content center">
                <div className="inner">
                    <h1></h1>
                    <div><p>{slide.description}</p></div>
                    <button>{slide.button}</button>
                </div>
            </div>)}
        </Slider>
  </div>
  )
}

export default careerSlider;