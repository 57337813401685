import React from 'react'
import '../../App.css';
import Cards from '../Cards';
import Footer from '../footer/Footer';
//import ProductsMain from '../courses/ProductsMain';
//import HeroSectionCareers from '../HeroSectionCareers';
import CareersMain from '../careers/Careers';
import ModulesMain from '../courses/Cards';
import CareerSlider from './careerSlider';
import Blog from '../blog/Blog';
//import Requirements from '../requirements/Requirements';

function Careers() {
    return (
        <div>
            {/**<HeroSectionCareers /> */}
            <CareerSlider />
            <Cards />
            <CareersMain />
            <ModulesMain />
            {/** 
             * <ModulesMain />
             * <ProductsMain />
             * <Requirements />
             * <Blog />
             * 
            */}
            <Blog />
            <Footer />
        </div>
    )    
}
export default Careers;