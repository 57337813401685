import React from 'react';
import '../../App.css';
import Footer from '../footer/Footer';
import ProductsMain from '../courses/ProductsMain';
import CoursesSlider from './coursesSlider';
//import HeroSectionCourses from '../HeroSectionCourses';
import Coursesmain from '../courses/Courses';
import ModulesMain from '../courses/Cards';
import Blog from '../blog/Blog';
import Requirements from '../requirements/Requirements';
import Cards from '../Cards';


function Courses() {
    return (
        <div>
            <CoursesSlider />
            {/** <HeroSectionCourses /> */}
            <Cards />
            <Coursesmain />
            <ModulesMain />
            <ProductsMain />
            <Requirements />
            <Blog />
            <Footer />
        </div>
    )    
}
export default Courses;