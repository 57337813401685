import React from 'react';
import ProductItem from './ProductItem';


function ProductsMain() {
  return (
    <div className='products__main'>
        <div className='products__container'>
                <div className='products__wrapper'>
                    <ul className='products__items'>
                        <ProductItem 
                          src='images/facilities-01.png'
                          text=''
                          label=''
                          path='/products'
                        />
                        <ProductItem 
                          src='images/facilities-02.png'
                          text=''
                          label=''
                          path='/products'
                        />
                    </ul>
                    <ul className='products__items'>
                        <ProductItem 
                          src='images/facilities-03.png'
                          text=''
                          label=''
                          path='/products'
                        />
                        <ProductItem 
                          src='images/facilities-04.png'
                          text='The job of an aircraft radiotrician is to carry out the maintenance of all communication systems on the aircraft, including radar and related systems...'
                          label='DRY DISCONNECT COUPLINGS'
                          path='/products'
                        />
                    </ul>
                  </div>
            </div>
    </div>
  );
}

export default ProductsMain;