import React from 'react';
import { Button } from './Button';
import '../App.css';
import './HeroSection.css';

function HeroSectionContact() {
    return (
        <div className='hero-container-contact-us' id='contact'>
            <div className='hero-container-inner'>
            {/*
                <h1>Contact Us</h1>

            <div className='hero-btns'>
                <Button 
                    className='btns'
                    buttonStyle='btn--primary'
                    buttonSize='btn--large'
                >
                ENQUIRE
                </Button>
            </div>
                
            */}
            </div>
            
        </div>
    )
}

export default HeroSectionContact;
