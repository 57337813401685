import React from 'react';
import './abouthome.css';
//import { google, dropbox, slack, shopify } from './imports';

const AboutHome = () => {
    return (
        <div className="gpt3__abouthome section__padding">
            <div className='gpt3__abouthome-content'>
                <h1>ABOUT US</h1>
            </div>
            
        </div>
    )
}

export default AboutHome;
